import React, { useState, useEffect } from 'react';
import axios from 'axios';


const ContactPopup = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);




  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !name || !message) return;

    setIsSubmitting(true);
  
    try {
      await sendEmail();
      setEmail('');
      setName('');
      setMessage('');
      onClose();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

 
  const sendEmail = async () => {
    const functionURL = 'https://us-central1-striped-rhino-401016.cloudfunctions.net/store_contact_us_emails';
    const postData = { email, name, message };
    try {
      const response = await axios.post(functionURL, postData);
      console.log('Response:', response.data);
    } catch (error) {
      throw error;
    }

   


  };

  return (
    <div className={`fixed z-10 inset-0 ${isOpen ? '' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-2/3 bg-white text-buttonViolet p-6 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
          <form onSubmit={handleSubmit} className='text-black'>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-600">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border p-2 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-600">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full border p-2 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-600">Message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full border p-2 rounded"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className={`bg-buttonViolet text-white px-4 py-2 rounded ${isSubmitting ? 'opacity-50' : 'hover:bg-mainViolet'} mr-4`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            <button
              onClick={onClose}
              className="text-white px-4 py-2 rounded bg-black cursor-pointer"
            >
              Close
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPopup;