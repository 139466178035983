import React from 'react'
import { useState } from 'react';
import ContactPopup from './ContactPopup'
import { Link } from 'react-router-dom';

export default function Footer() {

    const [isContactPopupOpen, setContactPopupOpen] = useState(false);

  const openContactPopup = () => {
    setContactPopupOpen(true);
  };

  const closeContactPopup = () => {
    setContactPopupOpen(false);
  };

  const handleContactLinkClick = (e) => {
    e.preventDefault();
    openContactPopup();
  };


  return (
    <div>
      <div style={{ position: 'relative', zIndex: 9999,top:'580vh' }}>
<div className="bg-white dark:bg-mainViolet">
    <div className="mx-90 w-full max-w-screen-3xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
              <a href="https://alzohealth.com/" className="flex items-center">
                  <img src="https://storage.googleapis.com/alzohealth-public-assets/AlzoHealth_logo_2024%20(1).png" className="h-24  mr-3" alt="FlowBite Logo" />
                  <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">Alzohealth</span>
              </a>
          </div>
          
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                  <h2 className="mb-6 text-sm font-semibolduppercase text-white">Resources</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                          <a href="https://alzo.health/" className="hover:underline">AlzoHealth</a>
                      </li>
                      <li className="mb-4">
                      
                          <a href="#" className="hover:underline" onClick={handleContactLinkClick}>Contact Us</a>
                          <ContactPopup isOpen={isContactPopupOpen} onClose={closeContactPopup} />
                      </li>
                  </ul>
              </div>
              
              <div>
                  <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                          <a href="https://linkedin.com/company/alzo-health" className="hover:underline " target='new'>LinkedIn</a>
                      </li>
                      <li>
                          <a href="https://github.com/Alzo-Health" className="hover:underline" target='new'>Github</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                        <Link to = 'https://alzo.health/termsOfService'>
                            <button className="hover:underline">Terms of Service</button>
                        </Link>   
                      </li>
                      <li>
                          <Link to = 'https://alzo.health/privacyPolicy'>
                          <button className="hover:underline">Privacy Policy</button>
                          </Link>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <hr className="my-6 border-lightViolet sm:mx-auto  lg:my-8" />

            <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-white sm:text-center">© 2023 <a href="https://flowbite.com/" className="hover:underline">AlzoHealth</a>. All Rights Reserved.
                </span>
                <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">

                <a href="#" className="text-white hover:text-lightViolet">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759" fill="white"/>
                    </svg>
                </a>
              <a href="#" className="text-white hover:text-lightViolet">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z" fill="white"/></svg>
              </a>
              <a href="#" className="text-white hover:text-lightViolet">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-4.466 19.59c-.405.078-.534-.171-.534-.384v-2.195c0-.747-.262-1.233-.55-1.481 1.782-.198 3.654-.875 3.654-3.947 0-.874-.312-1.588-.823-2.147.082-.202.356-1.016-.079-2.117 0 0-.671-.215-2.198.82-.64-.18-1.324-.267-2.004-.271-.68.003-1.364.091-2.003.269-1.528-1.035-2.2-.82-2.2-.82-.434 1.102-.16 1.915-.077 2.118-.512.56-.824 1.273-.824 2.147 0 3.064 1.867 3.751 3.645 3.954-.229.2-.436.552-.508 1.07-.457.204-1.614.557-2.328-.666 0 0-.423-.768-1.227-.825 0 0-.78-.01-.055.487 0 0 .525.246.889 1.17 0 0 .463 1.428 2.688.944v1.489c0 .211-.129.459-.528.385-3.18-1.057-5.472-4.056-5.472-7.59 0-4.419 3.582-8 8-8s8 3.581 8 8c0 3.533-2.289 6.531-5.466 7.59z"fill="white"/></svg>
              </a>
          </div>
      </div>
    </div>
</div>
</div>
    </div>
  )
}
