import React, { useState,useEffect} from "react";
import "antd/dist/antd.min.css";
import { Radio, FormControlLabel, Button } from "@mui/material";
import "./Questionnaire1.css";
import axios from 'axios';
//import questionData from "./questions.json"
import Header from "../components/Header";
import  Footer from "../components/Footer";

var user_id;
var u_id;
var response;
var year;

const Question = ({ top, children, updateScore }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [years, setYears] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = new URLSearchParams(window.location.search);
        const m =url.get('self');
        console.log("self : ",m)
        if(m == 0){
        const y = await axios.get('https://us-central1-striped-rhino-401016.cloudfunctions.net/Questionnaire_New-2');
      
        setYears(y.data);}
        else{
          const y = await axios.get('https://us-central1-striped-rhino-401016.cloudfunctions.net/Questionnaire_New-4');
      
        setYears(y.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [])

  year = Object.keys(years).reduce((count) => count + 1, 0);
  console.log("year = ",year);
  const handleOptionClick = (group, year,top) => {
    
    updateScore(group, year,top);

  };

  const renderRadioInputs = () => {
    const radioInputs = [];
   var p1;
   var groupNumber = 1;
    var groupchild=0;
   let p=0.5;
    top=(top-123)/19.5;
    //console.log("top",top);
   if(year===1){
      for (let i = 0; i < 8; i++) {
        const year = i % 2 === 0 ? 2020 : 2023; 
        
        const nameAttribute = i % 2 === 1 ? `group${groupchild+top}child` : `group${groupNumber+top}${p}`;
        
        let val;
      
        if (i === 0) {
          val = 0;
          radioInputs.push(
            <input
              key={i}
              type="radio"
              className={`group-child${i}`}
              style={{ backgroundColor: "white" }}
              onClick={() => handleOptionClick(val, year,top)}
              name={nameAttribute}
            />
          ) ;
        } else if (i===2){
          // Repeat values from 0 to 3 for the next iterations
          val = 1;
          radioInputs.push(
            <input
              key={i}
              type="radio"
              className={`group-child${i}`}
              style={{ backgroundColor: "white" }}
              onClick={() => handleOptionClick(val, year,top)}
              name={nameAttribute}
            />
          ) ;
        }else if (i===4){
          val=2;
          radioInputs.push(
            <input
              key={i}
              type="radio"
              className={`group-child${i}`}
              style={{ backgroundColor: "white" }}
              onClick={() => handleOptionClick(val, year,top)}
              name={nameAttribute}
            />
          ) ;
        }else if (i===6){
          val=3;
          radioInputs.push(
            <input
              key={i}
              type="radio"
              className={`group-child${i}`}
              style={{ backgroundColor: "white" }}
              onClick={() => handleOptionClick(val, year,top)}
              name={nameAttribute}
            />
          ) ;
        }
    }
  }
   
    if(year>=2){
      for (let i = 0; i < 8; i++) {
        const year = i % 2 === 0 ? 2020 : 2023;
        
        const nameAttribute = i % 2 === 1 ? `group${groupchild+top}child` : `group${groupNumber+top}${p}`;
        
        let val;
      
        if (i === 0) {
          val = 0;
        } else if (i===2){
          // Repeat values from 0 to 3 for the next iterations
          val = 1;
        }else if (i===4){
          val=2;
        }else if(i===6){
          val=3;
        }else if(i===1){
          val=0;
        }else if(i===3){
          val=1;
        }else if(i==5){
          val=2;
        }else if(i===7){
          val=3;
        }
        radioInputs.push(
          <input
            key={i}
            type="radio"
            className={`group-child${i}`}
            style={{ backgroundColor: "white" }}
            onClick={() => handleOptionClick(val, year,top)}
            name={nameAttribute}
          />
        ) ;
      }
    }
    if(year>=3){
      var c=0
      for (let i = 8; i < 8+(4*(year-2)); i++){
        let val;
        let name;
        
        const year = i % 2 === 0 ? 2021 : 2024; 
        if (i === 8) {
          val = 0;
          name=`year${groupchild+top}`;
          const year=2021
        } else if (i===9){
          // Repeat values from 0 to 3 for the next iterations
          val = 1;
          name=`year${groupchild+top}`;
          const year=2021
        }else if (i===10){
          val=2;
          name=`year${groupchild+top}`;
          const year=2021
        }else if(i===11){
          val=3;
          name=`year${groupchild+top}`;
          const year=2021
        }else if(i===12){
          val=0;
          name=`year1_${groupchild+top}`;
          const year=2024
        }else if(i===13){
          val=1;
          name=`year1_${groupchild+top}`;
          const year=2024
        }else if(i==14){
          val=2;
          name=`year1_${groupchild+top}`;
          const year=2024
        }else if(i===15){
          val=3;
          name=`year1_${groupchild+top}`;
          const year=2024
        }
        
        radioInputs.push(
          <input
            key={i}
            type="radio"
            className={`group-child${i}`}
            style={{ backgroundColor: "white" }}
            onClick={() => handleOptionClick(val, year ,top)}
            name={name}
          />
        )
      }
    }
    return radioInputs;
    }
    
  ;
console.log(user_id);
  return (
    <div className="how-often-do" style={{ top: `${top}vh` }}>
      {children }
      <div className="line-div" />
      
      <div className="parent">
        <b className="b">{years.year1}</b>
        <b className="b1">{years.year2}</b>
        <b className="b2">{years.year3}</b>
        <b className="b3">{years.year4}</b>
      
        <div className="ellipse-parent">
        {renderRadioInputs() }

        
        </div>
        
      </div>
      
    </div>
   
  );
};


const Questionnaire1 = () => {
  var userResponses={};
  const [selectedOption, setSelectedOption] = useState({ index: null, year: null });
  var totalScore;
  const [questionsData, setQuestionsData] = useState({
    'questions':[] 
  });
 
 
  const [uid, setUid] = useState("");
  const [scores2020, setScores2020] = useState(Array(22).fill(0)); // Initialize with appropriate values
  const [scores2023, setScores2023] = useState(Array(22).fill(0));
  const [scores2024, setScores2024] = useState(Array(22).fill(0));
  const [scores2025, setScores2025] = useState(Array(22).fill(0));

  const handleSubmit = async () => {
    const totalScore2021 = scores2020.reduce((acc, score) => acc + score, 0);
    const totalScore2024 = scores2023.reduce((acc, score) => acc + score, 0);
    const totalScore2017 = scores2024.reduce((acc, score) => acc + score, 0);
    const totalScore2028 = scores2025.reduce((acc, score) => acc + score, 0);
    userResponses['response2020']=scores2020;
    userResponses['response2023']=scores2023;
    userResponses['response2025']=scores2025;
    userResponses['response2024']=scores2024;
    userResponses['scores2020']=totalScore2021;
    userResponses['scores2023']=totalScore2024;
    userResponses['scores2021']=totalScore2017;
    userResponses['scores2024']=totalScore2028;
     userResponses['score']=Math.floor(((totalScore2021+totalScore2024+totalScore2017+totalScore2028)/(44*year))*10);
    userResponses['max_score']=10;

     console.log(userResponses)
     const userResponsesJSON = JSON.stringify(userResponses);
    try {   
      // Create a data object that includes the userResponses and uid

      const user_id = `${uid}`;
      
      const requestData = {
        userResponses:userResponsesJSON,
        uid: user_id,
      };

      console.log("Hello");
      console.log(user_id);
    console.log(requestData);
      
      // Use axios.post to send the JSON data to the server
      await axios.post('https://us-central1-striped-rhino-401016.cloudfunctions.net/store-questionnaire-1', requestData);

      // Set the submitted state to true

      console.log('Data submitted successfully.');
    } catch (error) {
      console.error('Error submitting data:', error);
    }
        console.log(uid);
	     const newTabURL = `https://mini-cog.alzo.health/?uid=${uid}`;
window.location.href = newTabURL;

      
  };
  


  useEffect(() => {
    async function fetchData() {
      try {
        const url = new URLSearchParams(window.location.search);
        const x =url.get('self');
        console.log("self : ",x)
        if(x == 0){
        response = await axios.get('https://us-central1-striped-rhino-401016.cloudfunctions.net/Questionnaire_New-1');
        }else{
          response = await axios.get('https://us-central1-striped-rhino-401016.cloudfunctions.net/Questionnaire_New-3');
        }
      
        setQuestionsData(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
    const urlParams = new URLSearchParams(window.location.search);
     user_id = urlParams.get('uid');

    
     
    if (user_id) {
      setUid(user_id);
      console.log('Received UID in the new tab:');
      console.log(user_id);
      
      console.log("self = ",u_id);
    } else {
      console.error('UID parameter not found in the URL');
    }
  }, []);
  

  const updateScore = (index, year, top) => {
    // ... rest of the code
    if (year === 2020) {
      console.log("Year that is getting activated: ",year)
      setScores2020(prevScores => {
        const newScores = [...prevScores];
        newScores[top] = index;
        return newScores;
      });
    } else if (year === 2023) {
      console.log("Year that is getting activated: ",year)
      setScores2023(prevScores => {
        const newScores = [...prevScores];
        newScores[top] = index;
        return newScores;
      });
    }else if(year === 2021){
      console.log("Year that is getting activated: ",year)
      setScores2025(prevScores => {
        const newScores = [...prevScores];
        newScores[top] = index;
        return newScores;
       
      });
    }else if(year === 2024){
      console.log("Year that is getting activated: ",year)
      setScores2024(prevScores => {
        const newScores = [...prevScores];
        newScores[top] = index;
        return newScores;
      });
    }
  };
  console.log("Scores 2020:", scores2020);
  console.log("Scores 2023:", scores2023);
  console.log("Score2024: ",scores2024);
  console.log("Score2025: ",scores2025);

  const maxWidth = window.innerWidth;
  console.log("Max Width:", maxWidth);
  console.log(selectedOption)
  console.log(questionsData);

 /*const questions = questionsData.questions*/


  return (
    
   <>
  
  <div className="head">
    <Header/>
    
    
    <div className="questionnaire">
      
    
  
       
      
      <div className="questionnaire-child" />
      
      <div className="self-assessment-for-early">
        
        Assessment for Early Signs of Alzheimer's Disease
      </div>
     
      <div className="no-noticeable-change-or-sympto-parent">
        <div className="mild-or-occasional">
          No noticeable change or symptom
        </div>
        <div className="group-child" />
      </div>
      <div className="mild-or-occasional-occurrence-parent">
        <div className="mild-or-occasional">Mild or occasional occurrence</div>
        <div className="group-child" />
      </div>
      <div className="severe-or-constant-occurrence-parent">
        <div className="mild-or-occasional">Severe or constant occurrence</div>
        <div className="group-child" />
      </div>
      <div className="questionnaire-inner">
      
        <div className="moderate-or-frequent-occurrenc-parent">
          
          <div className="mild-or-occasional">
          
            Moderate or frequent occurrence
            
          </div>
          <div className="group-child" />
        </div>
      </div>
      <div className="questionnaire-item" />
      {
      questionsData.questions.map((question,index) => (
        
        <Question key={index} top={123 + index * 19.5} updateScore={updateScore} >
          <div className="block">
      {"Q"+(index+1) +" : "+ question}

      {index % 2 === 0 && (
        <div className="questionnaire-child1" ></div>

      )}
      </div>
       
     
    </Question>
         
   
      ))}
        
     
      <div className="options">
        <b className="no-noticeable-change2">No noticeable change or symptom</b>
        <b className="mild-or-occasional1">Mild or occasional occurrence</b>
        <b className="moderate-or-frequent1">Moderate or frequent occurrence</b>
        <b className="severe-or-constant1">Severe or constant occurrence</b>
        
        
      </div>
      <div className="submit-button">
      <Button variant="contained"  onClick={handleSubmit} className="bg-blue-700">
            
           submit
          </Button>
         
         
        </div>
        
      <div className="to-assess-your">
       To evaluate your cognitive health, please provide ratings for the questions listed below, corresponding to the specified years. You can base your ratings on your observations. The following options are available:
      </div>
      
      <img className="you-4-1-1" alt="" src="/you-4-1-1@2x.png" />
      
    </div>
    <Footer/>
    </div>
   
    </>

    
     
  );
};

export default Questionnaire1;
